import { HorsedayIconProps } from "./IconProps";

const Icon = (props: HorsedayIconProps) => {
  const stroke: string = props.color ?? "white";
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 1.72803H17V16.9988H1V1.72803H5"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.60156 11.1863L8.20156 12.6406L11.4016 9.73193"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 1.00146V5.36453H7.4C7.4 4.561 8.116 3.91018 9 3.91018C9.884 3.91018 10.6 4.561 10.6 5.36453H13V1.00146H5Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Icon;
