import { HorsedayIconProps } from "./IconProps";

const Icon = (props: HorsedayIconProps) => {
  const stroke: string = props.color ?? "white";
  return (
    <svg
      width={props.width ?? "20"}
      height={props.height ?? "21"}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.98442 17.6578C10.0889 17.6571 10.1906 17.6244 10.2759 17.5641C10.3625 17.5112 10.4286 17.4304 10.4633 17.3351C10.494 17.2332 10.494 17.1246 10.4633 17.0227C10.4425 16.9201 10.3917 16.826 10.3175 16.7521C10.2427 16.6811 10.1485 16.634 10.0469 16.6167C9.94865 16.5856 9.84319 16.5856 9.74496 16.6167C9.64617 16.6556 9.56229 16.7248 9.50551 16.8145C9.45158 16.9022 9.42278 17.0031 9.42224 17.106C9.40662 17.1746 9.40662 17.2457 9.42224 17.3142C9.44687 17.3779 9.48608 17.435 9.53674 17.4808C9.5853 17.5282 9.64165 17.567 9.70331 17.5953C9.77229 17.6062 9.84256 17.6062 9.91154 17.5953L9.98442 17.6578Z"
        fill={stroke}
      />
      <path
        d="M15.7206 14.545C15.8236 14.5445 15.9244 14.5157 16.0121 14.4617C16.0996 14.4025 16.1683 14.3194 16.2099 14.2223C16.241 14.124 16.241 14.0186 16.2099 13.9204C16.1976 13.8172 16.1497 13.7215 16.0746 13.6497C16.0007 13.5755 15.9065 13.5248 15.8039 13.5039C15.7057 13.4728 15.6002 13.4728 15.502 13.5039C15.4032 13.5428 15.3193 13.612 15.2625 13.7017C15.2009 13.7864 15.1681 13.8886 15.1688 13.9932C15.1688 14.1323 15.2233 14.2658 15.3206 14.3651C15.418 14.4643 15.5504 14.5215 15.6894 14.5242L15.7206 14.545Z"
        fill={stroke}
      />
      <path
        d="M16.2517 8.29869C16.3197 8.2983 16.387 8.28427 16.4496 8.25745C16.5122 8.23063 16.5688 8.19156 16.616 8.14253C16.7103 8.04474 16.7626 7.91398 16.7618 7.77816C16.7562 7.71505 16.7376 7.65378 16.7071 7.59823C16.6767 7.54268 16.635 7.49406 16.5848 7.45543C16.492 7.36757 16.369 7.3186 16.2412 7.3186C16.1134 7.3186 15.9905 7.36757 15.8977 7.45543C15.8035 7.53958 15.741 7.6535 15.7207 7.77816C15.7234 7.91716 15.7806 8.04955 15.8798 8.14688C15.9791 8.24421 16.1126 8.29872 16.2517 8.29869Z"
        fill={stroke}
      />
      <path
        d="M4.52924 14.0037C4.52757 13.8977 4.49892 13.7937 4.44597 13.7018C4.38345 13.62 4.30101 13.5555 4.20652 13.5144C4.10829 13.4833 4.00283 13.4833 3.9046 13.5144C3.80184 13.535 3.70747 13.5855 3.63337 13.6596C3.55927 13.7337 3.50874 13.8281 3.48818 13.9309C3.45772 14.0292 3.45772 14.1344 3.48818 14.2328C3.52983 14.3299 3.59848 14.413 3.68598 14.4722C3.77369 14.5261 3.87452 14.555 3.97748 14.5555C4.1147 14.5528 4.24557 14.4971 4.34262 14.4001C4.43967 14.303 4.49535 14.1722 4.49801 14.035L4.52924 14.0037Z"
        fill={stroke}
      />
      <path
        d="M4.0087 7.7781C4.02418 7.71308 4.02418 7.64532 4.0087 7.5803C3.98645 7.5179 3.95092 7.46107 3.90458 7.41373C3.85506 7.36261 3.79455 7.32345 3.72762 7.29921C3.6626 7.28373 3.59484 7.28373 3.52982 7.29921C3.42793 7.30064 3.32871 7.33195 3.24445 7.38924C3.16019 7.44653 3.09459 7.52728 3.0558 7.6215C3.017 7.71571 3.00673 7.81925 3.02622 7.91926C3.04571 8.01927 3.0941 8.11136 3.16544 8.18412C3.23819 8.25545 3.3303 8.30386 3.43031 8.32335C3.53032 8.34284 3.63385 8.33255 3.72807 8.29376C3.82228 8.25496 3.90302 8.18937 3.96031 8.10511C4.0176 8.02085 4.04892 7.92163 4.05035 7.81975L4.0087 7.7781Z"
        fill={stroke}
      />
      <path
        d="M3.8525 1.76074C3.50894 2.80181 1.52052 6.7058 1.52052 11.1303C1.43039 12.7303 1.85607 14.3169 2.73499 15.6568C3.61391 16.9968 4.89969 18.0193 6.40312 18.5739C8.73759 19.533 11.3561 19.533 13.6906 18.5739C15.1959 18.021 16.4839 16.9993 17.3648 15.6592C18.2457 14.3191 18.6729 12.7315 18.5835 11.1303C18.5835 7.55947 16.6576 2.80181 16.314 1.76074L13 2.5C14.0717 5.25552 14.6865 7.41992 14.8774 10.3703C14.9269 11.2768 14.6848 12.1753 14.1864 12.9341C13.6881 13.6929 12.9597 14.2721 12.1081 14.5867C10.7997 15.1179 9.33563 15.1179 8.02717 14.5867C7.17561 14.2721 6.44722 13.6929 5.94886 12.9341C5.45049 12.1753 5.20836 11.2768 5.25794 10.3703C5.25794 6.65374 7 2.5 7 2.5L3.8525 1.76074Z"
        stroke={stroke}
        strokeWidth="0.9786"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </svg>
  );
};

export default Icon;
