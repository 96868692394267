import CustomTrainingIcon from "@/components/icons/CustomTrainingIcon";
import MedicalIcon from "@/components/icons/MedicalIcon";
import NoteIcon from "@/components/icons/NoteIcon";
import RestDayIcon from "@/components/icons/RestDayIcon";
import ShoeingIcon from "@/components/icons/ShoeingIcon";
import TrackTrainingIcon from "@/components/icons/TrackTrainingIcon";
import {
  ActivityTypeEnum,
  EntitlementEnum,
  HorseTypeEnum,
  PriceCategoryEnum,
} from "@/openapi";
import { _ } from "@/pages/_app";
import {
  darkBrown,
  intensitySliderBrown,
  lightBrown,
  primaryBrown,
  restDayBlue,
  worldfengurGreen,
} from "./colors";
import { t } from "./translation";

export const countryEmoji = (country?: string) => {
  if (!country) return "";
  if (country == "en") {
    country = "GB";
  }
  // return country ?? " ? ";
  return String.fromCodePoint(
    ...[...country!.toUpperCase().split("")].map(
      (x) => 0x1f1a5 + x.charCodeAt(0)
    )
  );

  // return country!
  //     .toUpperCase()
  //     .replaceAll(RegExp(r'[A-Z]'), (Match match) => String.fromCharCode(match.group(0)!.codeUnitAt(0) + 127397));
};

export const activityTypeToString = (type: ActivityTypeEnum) => {
  return capitalize(type.replaceAll("_", " "));
};

export const capitalize = (text: string) => {
  var c = text.substring(0, 1);
  var rest = text.substring(1);
  return c.toUpperCase() + rest.toLocaleLowerCase();
};

export const activityTypeIcon = (
  type: ActivityTypeEnum,
  color?: string,
  size?: number
): JSX.Element => {
  const E = TrackTrainingIcon;

  switch (type) {
    case "TRAINING_SESSION":
    case "TAGGED_TRAINING":
      return (
        <TrackTrainingIcon
          color={color ?? activityTypeColor(type)}
          width={size}
          height={size}
        />
      );
    case "CUSTOM_TAGGED_TRAINING":
    case "CUSTOM_TRAINING_SESSION":
      return (
        <CustomTrainingIcon
          height={size ?? 14}
          width={size ?? 14}
          color={color ?? activityTypeColor(type)}
        />
      );
    case "TAGGED_MEDICAL":
    case "MEDICAL":
      return (
        <MedicalIcon
          height={size}
          width={size}
          color={color ?? activityTypeColor(type)}
        />
      );
    case "SHOEING":
    case "TAGGED_SHOEING":
      return (
        <ShoeingIcon
          height={size}
          width={size}
          color={color ?? activityTypeColor(type)}
        />
      );
    case "NOTE":
      return (
        <NoteIcon
          width={size}
          height={size}
          color={color ?? activityTypeColor(type)}
        />
      );
    case "REST_DAY":
      return (
        <RestDayIcon
          width={size}
          height={size ?? 18}
          color={color ?? activityTypeColor(type)}
        />
      );
    default:
      return (
        <TrackTrainingIcon
          width={size}
          height={size}
          color={color ?? activityTypeColor(type)}
        />
      );
  }
};

export const activityTypeColor = (type: ActivityTypeEnum): string => {
  switch (type) {
    case "TRAINING_SESSION":
    case "TAGGED_TRAINING":
      return lightBrown;
    case "CUSTOM_TAGGED_TRAINING":
    case "CUSTOM_TRAINING_SESSION":
      return worldfengurGreen;
    case "MEDICAL":
    case "TAGGED_MEDICAL":
      return intensitySliderBrown;
    case "SHOEING":
    case "TAGGED_SHOEING":
      return darkBrown;
    case "NOTE":
      return primaryBrown;
    case "REST_DAY":
      return restDayBlue;
    default:
      return lightBrown;
  }
};

export const activityTypeTailwindColor = (type: ActivityTypeEnum): string => {
  switch (type) {
    case "TRAINING_SESSION":
    case "TAGGED_TRAINING":
      return "light-brown";
    case "CUSTOM_TAGGED_TRAINING":
    case "CUSTOM_TRAINING_SESSION":
      return "worldfengur-green";
    case "MEDICAL":
    case "TAGGED_MEDICAL":
      return "intensity-slider-brown";
    case "SHOEING":
    case "TAGGED_SHOEING":
      return "dark-brown";
    case "NOTE":
      return "primary-brown";
    case "REST_DAY":
      return "rest-day-blue";
    default:
      return "light-brown";
  }
};

export const dot = "•";

export const distanceToReadable = (distance?: number): string => {
  if (!distance) {
    return "0 km";
  }
  if (distance < 1000) {
    return distance.toFixed(0) + " m";
  }
  return (distance / 1000).toFixed(2) + " km";
};

export const timeToReadable = (secs?: number): string => {
  if (!secs) {
    return "0 secs";
  }
  if (secs < 60) {
    return secs.toFixed(0) + " " + _("secs");
  }
  if (secs < 3600) {
    return (secs / 60).toFixed(0) + " " + _("mins");
  }

  return (secs / 3600).toFixed(1) + " " + _("hours");
};

export const speedToReadable = (
  distance?: number,
  time?: number,
  speed?: number
) => {
  if (speed != null) {
    return speed.toFixed(1) + " km/h";
  }
  if (!distance || !time) {
    return "0 km/h";
  }
  return ((distance / time) * 3.6).toFixed(2) + " km/h";
};

export const forSalePriceCategoryToString = (type?: PriceCategoryEnum) => {
  if (!type) {
    return "";
  }
  return t("horse-price-category-" + type.toString());
};

export const horseTypeToString = (type?: HorseTypeEnum) => {
  if (!type) {
    return "";
  }
  return t("horse-sale-category-" + type.toString());
};

export const activitiesPlural = (numActs?: number) => {
  if (numActs === 1) {
    return t("activity");
  }
  return t("activities");
};

export const entitlementToString = (
  entitlement: EntitlementEnum | string
): string => {
  if (entitlement === "BROWSING") {
    return _("sub__browsing");
  }
  if (entitlement === "PRO") {
    return _("sub__premium");
  }
  return _("sub__free");
};

export const entitlementColor = (entitlement: EntitlementEnum): string => {
  if (entitlement === "BROWSING") {
    return "#524231";
  }
  if (entitlement === "PRO") {
    return "#886C4E";
  }
  return "rgba(14, 12, 11, 0.20);";
};

export const durationToBillingCycle = (duration: "MONTH" | "YEAR") =>
  duration === "MONTH" ? _("Monthly billing cycle") : _("Yearly billing cycle");
