import { HorsedayIconProps } from "./IconProps";

const Icon = (props: HorsedayIconProps) => {
  const stroke: string = props.color ?? "white";
  const width = (props.width ?? 20).toString();
  const height = (props.height ?? 22).toString();
  const viewBox = "0 0 " + width + " " + height;
  return (
    <svg
      width={props.width ?? "20"}
      height={props.height ?? "22"}
      viewBox={"0 0 20 22"}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.97928 3.59457L6.77476 14.69L11.578 17.1589L12.8845 21.0015C12.8845 21.0015 5.77569 21.0015 5.59317 21.0015L5.81411 17.889L3.06666 17.0148L0.540161 9.15672"
        stroke={stroke}
        strokeWidth="0.960647"
        strokeLinejoin="bevel"
      />
      <path
        d="M5.73657 19.2338L11.6349 17.2837"
        stroke={stroke}
        strokeWidth="0.960647"
        strokeMiterlimit="10"
      />
      <path
        d="M15.6893 1L18.9363 6.08182L18.6962 12.2011L16.112 15.7459L12.0389 9.72267L14.8728 7.44594L14.6806 5.90891L10.0119 1"
        stroke={stroke}
        strokeWidth="0.960647"
        strokeLinejoin="bevel"
      />
      <path
        d="M13.9407 8.56989L18.6191 12.3164"
        stroke={stroke}
        strokeWidth="0.960647"
        strokeMiterlimit="10"
      />
    </svg>
  );
};

export default Icon;
