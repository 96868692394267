import { HorsedayIconProps } from "./IconProps";

const MedicalIcon = (props: HorsedayIconProps) => {
  const stroke: string = props.color ?? "white";
  return (
    <svg
      width={props.width ?? "20"}
      height={props.height ?? "17"}
      viewBox="0 0 20 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99682 4.39434L11.7876 2.39129C12.194 1.93211 12.6977 1.56931 13.262 1.32927C13.8263 1.08922 14.437 0.977969 15.0497 1.00361C15.6624 1.02925 16.2616 1.19113 16.8039 1.47748C17.3461 1.76383 17.8178 2.16745 18.1844 2.65898C18.7708 3.44843 19.0567 4.42101 18.9907 5.40219C18.9247 6.38337 18.5112 7.30892 17.8244 8.01277L9.99682 15.9973L2.17843 8.01277C1.48995 7.30987 1.07531 6.38408 1.0093 5.40239C0.943291 4.42069 1.23024 3.44773 1.81842 2.65898C2.18519 2.16906 2.65635 1.76687 3.19776 1.48154C3.73916 1.19621 4.33728 1.03489 4.94874 1.00926C5.56019 0.983639 6.16969 1.09435 6.73307 1.33339C7.29645 1.57242 7.7996 1.93378 8.20607 2.39129L9.99682 4.39434Z"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </svg>
  );
};

export default MedicalIcon;
