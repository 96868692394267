import { HorsedayIconProps } from "./IconProps";

const TrackTrainingIcon = (props: HorsedayIconProps) => {
  const stroke: string = props.color ?? "white";
  const width = (props.width ?? 18).toString();
  const height = (props.height ?? 18).toString();
  const viewBox = "0 0 " + width + " " + height;
  return (
    <svg
      width={props.width ?? "18"}
      height={props.height ?? "18"}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.00153 13.2755C11.3579 13.2755 13.2682 11.3653 13.2682 9.00886C13.2682 6.65244 11.3579 4.74219 9.00153 4.74219C6.64512 4.74219 4.73486 6.65244 4.73486 9.00886C4.73486 11.3653 6.64512 13.2755 9.00153 13.2755Z"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M9.00062 9.55104C9.29517 9.55104 9.53395 9.31226 9.53395 9.01771C9.53395 8.72316 9.29517 8.48438 9.00062 8.48438C8.70607 8.48438 8.46729 8.72316 8.46729 9.01771C8.46729 9.31226 8.70607 9.55104 9.00062 9.55104Z"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M9.00062 9.55104C9.29517 9.55104 9.53395 9.31226 9.53395 9.01771C9.53395 8.72316 9.29517 8.48438 9.00062 8.48438C8.70607 8.48438 8.46729 8.72316 8.46729 9.01771C8.46729 9.31226 8.70607 9.55104 9.00062 9.55104Z"
        fill="white"
      />
      <path
        d="M14.8646 5.26641C15.7483 5.26641 16.4646 4.55006 16.4646 3.66641C16.4646 2.78275 15.7483 2.06641 14.8646 2.06641C13.981 2.06641 13.2646 2.78275 13.2646 3.66641C13.2646 4.55006 13.981 5.26641 14.8646 5.26641Z"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M16.84 7.4C16.9451 7.9168 17 8.45227 17 9C17 13.4181 13.4181 17 9 17C4.58187 17 1 13.4181 1 9C1 4.58187 4.58187 1 9 1C10.7691 1 12.4016 1.57227 13.7264 2.54453"
        stroke={stroke}
        strokeMiterlimit="10"
      />
    </svg>
  );
};

export default TrackTrainingIcon;
