import { HorsedayIconProps } from "./IconProps";

const RestDayIcon = (props: HorsedayIconProps) => {
  const stroke: string = props.color ?? "#332723";
  const width = (props.width ?? 26).toString();
  const height = (props.height ?? 26).toString();
  const viewBox = "0 0 " + width + " " + height;
  return (
    <svg
      width={props.width ?? "26"}
      height={props.height ?? "26"}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.8281 13.6292C22.0165 15.7247 21.5672 17.8282 20.5391 19.6639C19.5111 21.4995 17.9523 22.9814 16.0672 23.9154C14.1821 24.8494 12.0588 25.1917 9.97581 24.8975C7.89278 24.6033 5.9473 23.6862 4.39457 22.2667C2.84184 20.8472 1.75436 18.9914 1.27478 16.9429C0.795196 14.8943 0.945911 12.7486 1.70715 10.7873C2.46839 8.8259 3.80461 7.14043 5.54053 5.95196C7.27645 4.76349 9.33101 4.12751 11.4347 4.12744C11.8532 4.12749 12.2714 4.15243 12.687 4.20212"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.80859 11.8439L11.4373 14.5642V20.8261"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.1328 8.32166H17.2634L14.1328 11.8644H17.2634"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.7812 1H24.9982L19.7812 6.21761H24.9982"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default RestDayIcon;
